<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import logger from '@/Utils/logger';
import resourceHelper from '@/Utils/resourceHelper';
import reportRepository from '@/Repositories/reportRepository';
import reportDownloader from '@/Components/createReportModal/reportDownloader';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';

const emit = defineEmits < { 'close' }>();

const props = defineProps<{
  reportItemReferenceNumber: string,
  reportItemId: string,
  itemType: string
}>();

const modalTitle = computed(() => {
  if (props.itemType === CreateReportModalTypes.request) {
    return resourceHelper.getString('CreateRequestReportModalTitle');
  }
  if (props.itemType === CreateReportModalTypes.uploadFolder) {
    return resourceHelper.getString('CreateUploadFolderReportModalTitle');
  }
  return '';
});

onMounted(() => {
  reportTitle.value = reportDownloader.getDefaultReportTitle(props.reportItemReferenceNumber);
});

const titleRequiredValidationMessage = resourceHelper.getString('TitleRequired');

const reportTitle = ref(null);
const reportValidationMessage = ref(null);
const isCreatingReport = ref(false);

function onModalClose() {
  emit('close');
}

function onModalCreateReport() {
  validateData();
  if (reportValidationMessage.value === null) {
    createReport(reportTitle.value, props.reportItemId);
  }
}

async function reportTitleUpdated() {
  validateData();
}

// Validates data and updates validation message. Is valid if message is mull.
function validateData() {
  if (!reportTitle.value || reportTitle.value.trim() === '') {
    reportValidationMessage.value = titleRequiredValidationMessage;
    return;
  }
  reportValidationMessage.value = null;
}

async function createReport(reportTitle: string, reportItemId: string) {
  switch (props.itemType) {
    case CreateReportModalTypes.request:
      await createRequestReport(reportTitle, reportItemId);
      break;
    case CreateReportModalTypes.uploadFolder:
      await createUploadFolderReport(reportTitle, reportItemId);
      break;
  }
}

function createUploadFolderReport(reportTitle: string, reportItemId: string) {
  isCreatingReport.value = true;
  reportRepository.getUploadFolderReport(getReportParams(reportTitle, reportItemId))
      .then(data => {
        reportDownloader.downloadReport(data, reportTitle);
        logger.success('UploadFolderReportCreated');
      })
      .catch(jqXhr => {
        logger.error('UploadFolderReportFailed');
      })
      .finally(() => {
        isCreatingReport.value = false;
        onModalClose();
      });
}

async function createRequestReport(reportTitle: string, reportItemId: string) {
  isCreatingReport.value = true;

  reportRepository.getRequestReport(getReportParams(reportTitle, reportItemId))
      .then(data => {
        reportDownloader.downloadReport(data, reportTitle);
        logger.success('RequestReportCreated');
      })
      .catch(jqXhr => {
        logger.error('FailedToGenerateRequestReport', null, jqXhr);
      })
      .finally(() => {
        isCreatingReport.value = false;
        onModalClose();
      });
}

function getReportParams(reportTitle: string, itemId: string) {
  const result: any = {
    title: reportTitle,
    clientTimezoneOffset: new Date().getTimezoneOffset().toString(),
    clientTimezoneName: new Date().toTimeString().slice(9)
  };

  if (props.itemType === CreateReportModalTypes.request) {
    result.requestId = itemId;
  } else if (props.itemType === CreateReportModalTypes.uploadFolder) {
    result.selfResponseId = itemId;
  }

  return result;
}

</script>

<template>
  <ModalDialog
    :title="modalTitle"
    @close="onModalClose"
  >
    <template #default>
      <div>
        <div :class="[reportValidationMessage === null ? '' : 'has-error']">
          <label
            class="control-label required"
            for="reportTitleInput"
          >
            {{ $localize('Title') }}
          </label>
          <input
            id="reportTitleInput"
            v-model="reportTitle"
            class="form-control"
            type="text"
            required
            data-bind="textInput: title, ariaInvalid: title"
            maxlength="200"
            aria-describedby="titleValidationMessage"
            @input="reportTitleUpdated"
          >
          <span
            id="titleValidationMessage"
            class="help-block"
            data-bind="validationMessage: title"
          >
            {{ reportValidationMessage }}
          </span>
        </div>
        <div
          class="py-3"
        >
          {{ $localize('CreateReportModalText') }}
        </div>
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="createReportBtn"
        class="btn btn-primary"
        :disabled="isCreatingReport"
        @click="onModalCreateReport"
      >
        <span
          v-if="isCreatingReport"
          class="far fa-circle-notch fa-spin mr-2"
          aria-hidden="true"
        />
        <span>
          {{ $localize('Download') }}
        </span>
      </button>
    </template>
  </ModalDialog>
</template>
