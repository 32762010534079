<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import ConfigurableField from '@/Types/configurableField';
import resourceHelper from '@/Utils/resourceHelper';
import ValidationResultManager from '@/VueComponents/ConfigurableFields/validationResultManager';
import { Field } from 'vee-validate';

const props = defineProps<{
  configurableField: ConfigurableField,
  fieldValue: string,
  disabled: boolean,
  showMultiplePlaceholder: boolean
}>();
const emit = defineEmits<{(e: 'updated', fieldId: string, value: string, isValid: boolean, invalidReason: string): void}>();

const fieldValue = ref(props.fieldValue);

watch(() => props.fieldValue, newValue => {
  fieldValue.value = newValue;
});

// do not show errors if multiple placeholder is shown
// means field serves multiple items with different value
// therefore shown empty value for the field but actually it's not empty
watch(() => props.showMultiplePlaceholder, newValue => {
  if (newValue) {
    numeric?.value?.reset();
  } else {
    validate();
  }
});

const input = ref(null);
const numeric = ref(null);

async function validate(): Promise<Object> {
  return await numeric?.value?.validate();
}

async function emitUpdatedEvent() {
  const inputValue = input?.value?.value ?? '';
  const validationResult = await validate();
  const { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  emit('updated', props.configurableField.id, inputValue, isValid, error);
}

function getPlaceholder() {
  if (props.disabled) {
    return '';
  }

  if (props.showMultiplePlaceholder) {
    return resourceHelper.getString('MultipleValue');
  }

  return resourceHelper.getString('TheValueMustBeANumber');
}

onMounted(async () => {
  await validate();
});
</script>

<template>
  <Field
    ref="numeric"
    v-slot="{ errors, field }"
    v-model="fieldValue"
    :name="'numeric-' + configurableField.name"
    :rules="{ required: configurableField.isMandatory, numericConfigurableField: true }"
    :validate-on-input="true"
    :validate-on-change="false"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div
      :class="{ 'has-error': errors.length > 0 && !showMultiplePlaceholder }"
    >
      <label
        :for="'numeric-' + configurableField.name"
        :class="{ 'required': configurableField.isMandatory }"
      >
        {{ configurableField.displayName }}:
      </label>
      <input
        :id="'numeric-' + configurableField.name"
        ref="input"
        v-bind="field"
        type="text"
        class="form-control text"
        :placeholder="getPlaceholder()"
        :required="configurableField.isMandatory"
        :name="'numeric-' + configurableField.name"
        :value="fieldValue"
        :disabled="disabled"
        :aria-invalid="errors.length > 0 && !showMultiplePlaceholder"
        :aria-describedby="'errorText_'+configurableField.name"
        @input="emitUpdatedEvent"
      >
      <div class="help-block">
        <small :id="'errorText_'+configurableField.name">{{ showMultiplePlaceholder ? '' : errors[0] }}</small>
      </div>
    </div>
  </Field>
</template>