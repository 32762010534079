<template>
  <FilterArea
    :entities="groups"
    :selected-item-id="selectedGroupId"
    filter-area-class="filter-area-height-admin-groups"
    :item-label="getGroupLabel"
    :item-type="FilterAreaType.group"
  >
    <template #option-content="{ slotProps }">
      <div
        :id="slotProps.option.groupId"
        class="filter-area-list-item ellipsis"
        :class="{ 'active': slotProps.option.groupId === selectedGroupId }"
        :aria-selected="slotProps.option.groupId === selectedGroupId"
        @click="selectGroup(slotProps.option.groupId)"
      >
        <b>
          {{ getGroupLabel(slotProps.option) }}
        </b><br>
        <small class="heading ellipsis">
          {{ slotProps.option.membersSummary() }}
        </small>
      </div>
    </template>
  </FilterArea>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import FilterArea from '@/VueComponents/SharedComponents/FilterArea/FilterArea.vue';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';
import { GroupSummaryDto } from '@/Models/groupManagement/groupSummaryDto';

const props = defineProps<{
  selectedItemId: string | null
}>();

const groups = defineModel<GroupSummaryDto[]>('groups', { required: true });
const emit = defineEmits<{(e: 'selectedItemIdUpdated', selectedItemId: string | null): void }>();
const selectedGroupId = ref(props.selectedItemId);

const selectGroup = (groupId: string | null) => {
  selectedGroupId.value = groupId;
  emit('selectedItemIdUpdated', selectedGroupId.value);
};

const getGroupLabel = (group: GroupSummaryDto) => {
  return group.groupDetails.groupName;
};

watch(() => props.selectedItemId, newVal => {
  selectedGroupId.value = newVal;
});
</script>