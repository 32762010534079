<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import ItemsList from '@/VueComponents/ItemsList/ItemsList.vue';
import SelectUploadsDropdown from '@/VueComponents/Uploads/SelectUploadsDropdown.vue';
import TextConfigurableField from '@/VueComponents/ConfigurableFields/TextConfigurableField.vue';
import NumericConfigurableField from '@/VueComponents/ConfigurableFields/NumericConfigurableField.vue';
import ListConfigurableField from '@/VueComponents/ConfigurableFields/ListConfigurableField.vue';
import ItemsLoadingSpinner from '@/VueComponents/Loading/ItemsLoadingSpinner.vue';
import DatetimeConfigurableField from '@/VueComponents/ConfigurableFields/DateTimeConfigurableField.vue';
import HierarchicalConfigurableField from '@/VueComponents/ConfigurableFields/HierarchicalConfigurableField.vue';

import UploadSummaryModel from '@/VueComponents/Uploads/models/uploadSummaryModel';
import UploadMetadata from '@/VueComponents/Uploads/models/uploadMetadata';
import UploadFieldValue from '@/VueComponents/Uploads/models/uploadFieldValue';
import ListItem from '@/VueComponents/ItemsList/Models/listItem';
import ConfigurableField from '@/Types/configurableField';
import logger from '@/Utils/logger';
import resourceHelper from '@/Utils/resourceHelper';
import GetUploadMetadataRequest from '@/VueComponents/Uploads/models/getUploadMetadataRequest';
import GetUploadMetadataResponse from '@/VueComponents/Uploads/models/getUploadMetadataResponse';
import SaveUploadMetadataRequest from '@/VueComponents/Uploads/models/saveUploadMetadataRequest';
import UploadMetadataForSave from '@/VueComponents/Uploads/models/uploadMetadataForSave';
import UploadFieldValueForSave from '@/VueComponents/Uploads/models/uploadFieldValueForSave';
import ValidationState from '@/VueComponents/Uploads/models/validationState';
import UploadRepository from '@/Repositories/uploadRepository';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import ConfigurableFieldValue from '@/Types/configurableFieldValue';
import { ConfigurableFieldType } from '@/Types/Enums/configurableFieldType';
import moment from 'moment';
import constants from '@/constants';
import contextData from '@/contextData';
import MediaTypeConfigurableFieldManager from '@/Utils/mediaTypeConfigurableFieldManager';
import UploadField from '@/Types/uploadField';

const props = defineProps<{
  uploads: UploadSummaryModel[],
  uploadParentId: string,
  uploadParentItemType: UploadParentItemType,
  uploadParentItemIsCompleted: boolean
}>();

const emit = defineEmits<{'close', 'saved'}>();

const listItems = ref<ListItem[]>([]);
let uploadsMetadata: UploadMetadata[] = [];
const configurableFields = ref<ConfigurableField[]>([]);
const configurableFieldsValues = ref<ConfigurableFieldValue[]>([]);
const isLoading = ref(true);
const noConfigurableFieldsFound = ref(false);
const isSaving = ref(false);
let readonlyFieldForUploads: UploadField[] = [];

const fieldComponents = {
  TextConfigurableField,
  NumericConfigurableField,
  ListConfigurableField,
  DatetimeConfigurableField,
  HierarchicalConfigurableField
};

const subTitleText = computed(() => {
  if (listItems.value.length) {
    let result = resourceHelper.getString('EditUploadMetadataSelectorDisclaimer', {
      '0': listItems.value.filter(model => model.isSelected).length,
      '1': listItems.value.length
    });
    const invalidItems = listItems.value.filter(item => !item.isValid);
    if (invalidItems.length) {
      result += resourceHelper.getString('UploadsHaveInvalidMetadata', {
        '0': invalidItems.length
      });
    }
    return result;
  }
  return '';
});

const selectorData = computed(() => {
  const invalidUploadsCount = listItems.value.filter(item => !item.isValid).length;
  const availableForSelectionCount = listItems.value.length;
  const selectedCount = listItems.value.filter(item => item.isSelected).length;

  return {
    invalidUploadsCount,
    availableForSelectionCount,
    selectedCount
  };
});

function selectAll() {
  listItems.value.forEach(item => item.isSelected = true);
  updateConfigurableFieldsValues();
}

function selectFailed() {
  listItems.value.forEach(item => {
    item.isSelected = !item.isValid;
  });
  updateConfigurableFieldsValues();
}

function deselectAll() {
  listItems.value.forEach(item => item.isSelected = false);
  updateConfigurableFieldsValues();
}

function fieldValueUpdated(fieldId: string, fieldValue: string, isValid: boolean, invalidReason: string, fieldSubValue: string | null = null) {
  const selectedItems = listItems.value.filter(item => item.isSelected);
  selectedItems.forEach(item => {
    const uploadMetadata = uploadsMetadata.find(u => u.uploadId === item.id);
    if (uploadMetadata) {
      // Possibly that server returns empty metadata
      if (!uploadMetadata.uploadFieldValues) {
        uploadMetadata.uploadFieldValues = [];
      }
      const metadata = uploadMetadata.uploadFieldValues.find(u => u.fieldId === fieldId);
      if (metadata) {
        metadata.value = fieldValue;
        metadata.subGroupValue = fieldSubValue;
        metadata.validationState.isValid = isValid;
        metadata.validationState.invalidReason = invalidReason;
      } else {
        uploadMetadata.uploadFieldValues.push(new UploadFieldValue(fieldId, fieldValue, fieldSubValue, new ValidationState(isValid, invalidReason)));
      }

      updateListItemInvalidReasons(item, uploadMetadata);
    }
  });

  updateConfigurableFieldsValues();
}

function updateListItemInvalidReasons(listItem: ListItem, uploadMetadata: UploadMetadata) {
  const invalidMetadata = uploadMetadata.uploadFieldValues.filter(x => !x.validationState.isValid);
  if (invalidMetadata.length) {
    listItem.isValid = false;
    listItem.invalidReason = invalidMetadata.map(x => {
      const fieldName = configurableFields.value.find(field => field.id === x.fieldId)?.displayName ?? '';
      return `${fieldName}: ${x.validationState.invalidReason}`;
    }).join('\n');
  } else {
    listItem.isValid = true;
    listItem.invalidReason = '';
  }
}

function updateConfigurableFieldsValues() {
  const selectedItems = listItems.value.filter(item => item.isSelected);
  configurableFieldsValues.value = configurableFields.value.map(field => {
    let resultedValue = '';
    let resultedSubValue = '';
    // show multiple placeholder if more than one upload selected and
    // values of uploads differs
    let showMultiplePlaceholder = false;
    let showMultipleSubPlaceholder = false;
    let isParentFieldReadonly = false;
    // if no selected uploads => set values for configurable fields empty
    if (selectedItems.length !== 0) {
      const selectedUploadsMetadataForField = uploadsMetadata
          .filter(upload => selectedItems.some(item => item.id === upload.uploadId));

      const selectedValues = selectedUploadsMetadataForField
          .map(upload => upload.uploadFieldValues?.find(data => data.fieldId === field.id)?.value ?? '');

      const selectedSubValues = selectedUploadsMetadataForField
          .map(upload => upload.uploadFieldValues?.find(data => data.fieldId === field.id)?.subGroupValue ?? '');

      // if all uploads have the same metadata value for selected field
      // => pass it to the configurable field control
      // otherwise set empty value
      if (selectedValues.some(val => val !== selectedValues[0])) {
        resultedValue = '';
        showMultiplePlaceholder = true;
      } else {
        resultedValue = selectedValues[0];
        showMultiplePlaceholder = false;
      }

      // if parent value is multiple and sub values are not empty then subtype should be multiple as well
      if (selectedSubValues.some(val => val !== selectedSubValues[0]) ||
          (showMultiplePlaceholder && selectedSubValues.some(val => val))) {
        resultedSubValue = '';
        showMultipleSubPlaceholder = true;
      } else {
        resultedSubValue = selectedSubValues[0];
        showMultipleSubPlaceholder = false;
      }

      isParentFieldReadonly = readonlyFieldForUploads.some(x => x.fieldId === field.id &&
        selectedUploadsMetadataForField.some(y => y.uploadId === x.uploadId));
    }

    return {
      fieldId: field.id,
      fieldValue: resultedValue,
      fieldSubValue: resultedSubValue,
      showMultiplePlaceholder,
      showMultipleSubPlaceholder,
      isParentFieldReadonly
    };
  });
}

function getConfigurableFieldValue(fieldId: string) {
  const value = configurableFieldsValues.value.find(f => f.fieldId === fieldId);
  return value?.fieldValue;
}

function getConfigurableFieldSubValue(fieldId: string) {
  const value = configurableFieldsValues.value.find(f => f.fieldId === fieldId);
  return value?.fieldSubValue;
}

function getShowMulptiplePlaceholder(fieldId: string) {
  const value = configurableFieldsValues.value.find(f => f.fieldId === fieldId);
  return value?.showMultiplePlaceholder;
}

function getShowMulptipleSubPlaceholder(fieldId: string) {
  const value = configurableFieldsValues.value.find(f => f.fieldId === fieldId);
  return value?.showMultipleSubPlaceholder;
}

function getIsParentFieldReadonly(fieldId: string) {
  const value = configurableFieldsValues.value.find(f => f.fieldId === fieldId);
  return value?.isParentFieldReadonly;
}

function onModalClose() {
  emit('close');
}

async function onSave() {
  const uploadsWithMetadata = uploadsMetadata.filter(u => u.uploadFieldValues);
  if (uploadsMetadata.length === 0) {
    logger.warning('MetadataNoChangesWhereMade');
    return;
  }

  if (uploadsWithMetadata.some(u => u.uploadFieldValues.some(uploadFieldValue => !uploadFieldValue.validationState.isValid))) {
    logger.error('MetadataIsNotValid');
    return;
  }

  // destruct upload metadata to remove validation state before send to server
  const model = uploadsWithMetadata.map(upload => {
    const data = upload.uploadFieldValues.map(data => {
      let { fieldId, value, subGroupValue } = data;

      // save datetime value in server format
      if (value) {
        const field = configurableFields.value.find(f => f.id === fieldId);
        if (field?.type === ConfigurableFieldType.datetime) {
          // if user made any changes => use internal calendar date time format
          // if no changes provided => use server format
          const date = moment(value, [contextData.calendarDateTimeFormat, constants.dateTimeFormats.serverDateTimeFormat], true);
          if (!date.isValid()) {
            logger.error('MetadataIsNotValid');
            return;
          }
          value = date.format(constants.dateTimeFormats.serverDateTimeFormat);
        }
      }

      return new UploadFieldValueForSave(fieldId, value, subGroupValue);
    });
    return new UploadMetadataForSave(upload.uploadId, data);
  });

  isSaving.value = true;

  try {
    const result = await UploadRepository.saveUploadsMetadata(new SaveUploadMetadataRequest(props.uploadParentId, model),
        props.uploadParentItemType);
    if (result &&
        result.deletedConfigurableFieldNames &&
        result.deletedConfigurableFieldNames.length > 0) {
      result.deletedConfigurableFieldNames.map((name: string) => {
        const message = resourceHelper.getString('ConfigurableFieldRemovedOrUnassigned', { '0': name });
        logger.untranslatedWarning(message, undefined, undefined, true);
      });
    }
    logger.success('UploadMetadataSaved');
    emit('saved');
  } catch {
    logger.error('UploadMetadataFailedToBeSaved');
  } finally {
    isSaving.value = false;
  }
}

onBeforeMount(async () => {
  const model = new GetUploadMetadataRequest(props.uploadParentId, props.uploads.map(u => u.id));
  try {
    const response: GetUploadMetadataResponse = await UploadRepository.getUploadMetadataAndConfigurableFields(model, props.uploadParentItemType);
    if (response.configurableFields !== null && response.configurableFields.length > 0) {
      configurableFields.value.push(...response.configurableFields);
    } else {
      noConfigurableFieldsFound.value = true;
    }

    uploadsMetadata = response.uploadMetadata;
    uploadsMetadata.forEach(x => x.uploadFieldValues.forEach(fv => fv.validationState = new ValidationState(true, '')));

    listItems.value.push(...uploadsMetadata.map(metadata => {
      const upload = props.uploads.find(u => u.id === metadata.uploadId);
      const uploadType = UploadTypeResolver.localizeUploadType(upload?.type);
      return new ListItem(metadata.uploadId, upload?.name, uploadType, upload?.isSelected, true, '');
    }));

    readonlyFieldForUploads = MediaTypeConfigurableFieldManager.fillMetadataForMediaType(
        configurableFields.value, uploadsMetadata, props.uploads, response.uploadMediaTypesFileExtensions);
    updateConfigurableFieldsValues();
    makeInitialMetadataValidation();
    isLoading.value = false;
  } catch {
    logger.error('ErrorGettingConfigurableFieldsFromServer');
    emit('close');
  }
});

// Checks that metadata is provided for mandatory fields
function makeInitialMetadataValidation() {
  listItems.value.forEach((item: ListItem) => {
    const uploadMetadata = uploadsMetadata.find(u => u.uploadId === item.id);
    if (uploadMetadata) {
      configurableFields.value.forEach(field => {
        if (field.isMandatory) {
          if (!uploadMetadata.uploadFieldValues) {
            uploadMetadata.uploadFieldValues = [];
          }

          const metadata = uploadMetadata.uploadFieldValues.find(u => u.fieldId === field.id);
          const invalidReason = resourceHelper.getString('RequiredFieldRuleValidationMessage');
          if (metadata) {
            if (!metadata.value) {
              metadata.validationState.isValid = false;
              metadata.validationState.invalidReason = invalidReason;
            } else if (field.type === ConfigurableFieldType.hierarchical && !metadata.subGroupValue) {
              metadata.validationState.isValid = false;
              metadata.validationState.invalidReason = invalidReason;
            }
          } else {
            uploadMetadata.uploadFieldValues.push(new UploadFieldValue(field.id, '', '', new ValidationState(false, invalidReason)));
          }
        }
      });
      updateListItemInvalidReasons(item, uploadMetadata);
    }
  });
}
</script>

<template>
  <ModalDialog
    :title="$localize(uploadParentItemIsCompleted ? 'ViewUploadMetadataModalTitle' : 'EditUploadMetadataModalTitle')"
    :is-danger="false"
    :is-wide="true"
    @close="onModalClose"
  >
    <template #default>
      <div v-if="isLoading">
        <ItemsLoadingSpinner />
      </div>
      <div v-else>
        <div class="row title-container">
          <div class="col-xs-12">
            <div>
              <label class="label-for-selector">
                {{ subTitleText }}
              </label>
            </div>
            <SelectUploadsDropdown
              :failed-count="selectorData.invalidUploadsCount"
              :available-for-selection-count="selectorData.availableForSelectionCount"
              :selected-count="selectorData.selectedCount"
              :selected-failed-label="'SelectInvalidUploads'"
              @select-all="selectAll"
              @select-failed="selectFailed"
              @deselect-all="deselectAll"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 left-block">
            <div class="uploads-list-container">
              <ItemsList
                v-model="listItems"
                @item-selection-changed="updateConfigurableFieldsValues"
              />
            </div>
          </div>
          <div class="col-xs-6 right-block">
            <div
              v-if="listItems.some(item => item.isSelected) && !noConfigurableFieldsFound"
              class="config-fields-container"
            >
              <h4
                class="config-fields-title"
              >
                {{ $localize('EditUploadFieldsTitle') }}
              </h4>
              <component
                :is="fieldComponents[field.type + 'ConfigurableField']"
                v-for="field in configurableFields"
                :key="field.id"
                :configurable-field="field"
                :field-value="getConfigurableFieldValue(field.id)"
                :field-sub-value="getConfigurableFieldSubValue(field.id)"
                :disabled="uploadParentItemIsCompleted"
                :show-multiple-placeholder="getShowMulptiplePlaceholder(field.id)"
                :show-multiple-sub-placeholder="getShowMulptipleSubPlaceholder(field.id)"
                :is-parent-field-readonly="getIsParentFieldReadonly(field.id)"
                @updated="fieldValueUpdated"
              />
            </div>
            <div
              v-if="!listItems.some(item => item.isSelected) || noConfigurableFieldsFound"
              class="blank-area blank-area-height-edit-metadata"
            >
              <div v-if="!noConfigurableFieldsFound">
                <i
                  class="fas fa-upload"
                  aria-hidden="true"
                />
                <h4>{{ $localize('MetadataSelectUpload') }}</h4>
              </div>
              <div
                v-else
                class="no-configurable-fields-disclaimer-container"
              >
                <i
                  class="far fa-circle-exclamation validation-icon"
                  aria-hidden="true"
                />
                <h4>{{ $localize('MetadataNoConfigurableFieldsExisted') }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="listItems.some(item => !item.isValid)"
          class="validation-summary"
          aria-live="assertive"
        >
          <h5><strong>{{ $localize('UploadValidationFailed') }}</strong></h5>
          <ul>
            <li
              id="errorMessage"
            >
              {{ $localize('MetadataValidationError') }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        :title="$localize('Close')"
        @click="onModalClose"
      >
        {{ $localize('Close') }}
      </button>
      <button
        v-if="!isLoading && !noConfigurableFieldsFound && !uploadParentItemIsCompleted"
        class="btn btn-primary"
        aria-describedby="errorMessage"
        :disabled="isSaving"
        :title="$localize('Save')"
        @click="onSave"
      >
        <i
          v-if="isSaving"
          class="far fa-circle-notch fa-spin"
          aria-hidden="true"
        />
        {{ $localize('Save') }}
      </button>
    </template>
  </ModalDialog>
</template>
<style lang="scss" scoped>
    @import "sass/site/_colours.scss";
    @import "sass/site/_modal-sizes.scss";
    @import "sass/site/_forms.scss";
    @import "sass/site/_variables.scss";
    @import "sass/site/_empty-state.scss";

    .uploads-list-container {
        min-height: $edit-metadata-main-control-min-height;
        max-height: $modal-dialog-main-control-max-height;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid $background-light-grey;
    }
    .config-fields-container {
        min-height: $edit-metadata-main-control-min-height;
        max-height: $modal-dialog-main-control-max-height;
        overflow-y: auto;
        padding: 0 10px 10px 10px;
    }
    .config-fields-title {
      margin-top: 0px;
    }
    .label-for-selector {
        margin-left: 5px;
    }
    .title-container {
        margin-bottom: 10px;
    }
    .right-block{
      padding-left: 0px;
    }
    .no-configurable-fields-disclaimer-container{
      color: $validation-error-color;
    }
</style>