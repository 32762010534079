<template>
  <FilterArea
    :entities="users"
    :selected-item-id="selectedUserId"
    filter-area-class="filter-area-height-admin-users"
    :item-label="getUserLabel"
    :item-type="FilterAreaType.user"
  >
    <template #option-content="{ slotProps }">
      <div
        :id="slotProps.option.personaId"
        class="filter-area-list-item ellipsis"
        :class="{ 'active': slotProps.option.personaId === selectedUserId }"
        :aria-selected="slotProps.option.personaId === selectedUserId"
        @click="selectUser(slotProps.option.personaId)"
      >
        <b :title="slotProps.option.givenName + ' ' + slotProps.option.familyName">
          {{ slotProps.option.givenName + ' ' + slotProps.option.familyName }}
        </b>
        <br>
        <div class="filter-item-with-icons">
          <small
            class="heading ellipsis filter-area-item-heading"
            :title="slotProps.option.emailAddress"
          >
            {{ slotProps.option.emailAddress }}
          </small>
          <div class="item-icons">
            <div v-if="slotProps.option.isBusinessPrimaryContact">
              <i
                v-tooltip.top="{
                  class: 'request-list__tooltip',
                  value: $localize(AccountSpecificLabelsProvider.getAccountLabelKey('UserIsPrimaryBusinessExplanatoryText')),
                  pt: {
                    text: 'request-list__tooltip-text',
                    arrow: 'request-list__tooltip-arrow'
                  }
                }"
                class="far fa-envelope"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize(AccountSpecificLabelsProvider.getAccountLabelKey('UserIsPrimaryBusinessExplanatoryText')) }}</span>
            </div>
            <div v-if="slotProps.option.isBusinessAdminUser">
              <i
                v-tooltip.top="{
                  class: 'request-list__tooltip',
                  value: $localize('UserIsAdminExplanatoryText'),
                  pt: {
                    text: 'request-list__tooltip-text',
                    arrow: 'request-list__tooltip-arrow'
                  }
                }"
                class="fas fa-user-cog"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize('UserIsAdminExplanatoryText') }}</span>
            </div>
            <div v-if="slotProps.option.isPreregistered">
              <i
                v-tooltip.top="{
                  class: 'request-list__tooltip',
                  value: $localize('UserIsPreregisteredExplanatoryText'),
                  pt: {
                    text: 'request-list__tooltip-text',
                    arrow: 'request-list__tooltip-arrow'
                  }
                }"
                class="fas fa-user-slash"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize('UserIsPreregisteredExplanatoryText') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FilterArea>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import FilterArea from '@/VueComponents/SharedComponents/FilterArea/FilterArea.vue';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';
import UserModel from '@/Models/userModel';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';

const props = defineProps<{
    selectedItemId: string | null
  }>();

const users = defineModel<UserModel[]>('users', { required: true });
const emit = defineEmits<{(e: 'selectedItemIdUpdated', selectedItemId: string | null): void }>();
const selectedUserId = ref(props.selectedItemId);

const selectUser = (userId: string | null) => {
  selectedUserId.value = userId;
  emit('selectedItemIdUpdated', selectedUserId.value);
};

const getUserLabel = (user: UserModel) => {
  return user.givenName + ' ' + user.familyName;
};

watch(() => props.selectedItemId, newVal => {
  selectedUserId.value = newVal;
});
</script>