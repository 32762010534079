<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import ConfigurableField from '@/Types/configurableField';
import resourceHelper from '@/Utils/resourceHelper';
import ValidationResultManager from '@/VueComponents/ConfigurableFields/validationResultManager';
import { Field } from 'vee-validate';

const props = defineProps<{
  configurableField: ConfigurableField,
  fieldValue: string,
  disabled: boolean,
  showMultiplePlaceholder: boolean
}>();
const emit = defineEmits<{(e: 'updated', fieldId: string, value: string, isValid: boolean, invalidReason: string): void}>();

const fieldValue = ref(props.fieldValue);
watch(() => props.fieldValue, newValue => {
  fieldValue.value = newValue;
});

// do not show errors if multiple placeholder is shown
// means field serves multiple items with different value
// therefore shown empty value for the field but actually it's not empty
watch(() => props.showMultiplePlaceholder, newValue => {
  if (newValue) {
    text?.value?.reset();
  } else {
    validate();
  }
});

const maxInputLength = '1000';
const input = ref(null);
const text = ref(null);

const shouldNotValidate = !props.configurableField.mustValidateMask && props.configurableField.validationMasks.length > 0;

async function validate(): Promise<Object> {
  const result = await text?.value?.validate();
  if (!result.valid && validationWarningOnly) {
    result.valid = true;
  }
  return result;
}

async function emitUpdatedEvent() {
  const inputValue = input?.value?.value ?? '';
  const validationResult = await validate();
  const { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  emit('updated', props.configurableField.id, inputValue, isValid, error);
}

const validationWarningMessage = () => {
  const warning = resourceHelper.getString('ShouldMatch');
  if (!props.configurableField.maskPrompt) {
    const validationMaskMessage = resourceHelper.getString('ValidationMask');
    return `${warning} ${validationMaskMessage}`;
  }
  return `${warning} ${props.configurableField.maskPrompt}`;
};

function getPlaceholder() {
  if (props.disabled) {
    return '';
  }

  if (props.showMultiplePlaceholder) {
    return resourceHelper.getString('MultipleValue');
  }

  return props.configurableField.maskPrompt ? props.configurableField.maskPrompt :
    resourceHelper.getString('NotSpecified');
}

function getValidationFlags(errors: string[]): { shouldNotValidateAgainstMasks: boolean, hasRequired: boolean,
  hasMax: boolean, mustValidate: boolean} {
  // ' ' error defines configurable field has !mustValidateMask and validationMasks.length > 0
  const shouldNotValidateAgainstMasks = errors.some(error => error.includes(' '));
  const hasRequired = errors.some(error => error.includes(resourceHelper.getString('RequiredFieldRuleValidationMessage')));
  const hasMax = errors.some(error => error.includes(resourceHelper.getString('MaxLengthValidationMessage', { '0': maxInputLength })));
  const mustValidate = errors.some(error => error.includes(resourceHelper.getString('MustMatch')));

  return { shouldNotValidateAgainstMasks, hasRequired, hasMax, mustValidate };
}

let validationWarningOnly: boolean;
function getWarningMessage(errors: string[]): string {
  let message = ' ';
  validationWarningOnly = false;

  if (errors.length === 0) {
    return message;
  }

  const { shouldNotValidateAgainstMasks, hasRequired, hasMax, mustValidate } = getValidationFlags(errors);

  // affect in case if there are one error only, it's for validation masks and fields has flag !mustValidateMask
  if (!hasRequired && !hasMax && shouldNotValidateAgainstMasks && !mustValidate) {
    message = validationWarningMessage();
    validationWarningOnly = true;
  }

  return message;
}

// define a class for div based on errors and warnings
function defineClass(errors: string[]): string {
  if (props.showMultiplePlaceholder || props.disabled) {
    return '';
  }
  const { shouldNotValidateAgainstMasks, hasRequired, hasMax, mustValidate } = getValidationFlags(errors);
  if (!hasRequired && !hasMax && shouldNotValidateAgainstMasks && !mustValidate) {
    return 'has-warning';
  }
  if (hasRequired || hasMax || mustValidate) {
    return 'has-error';
  } else {
    return '';
  }
}

onMounted(async () => {
  await validate();
});
</script>

<template>
  <Field
    ref="text"
    v-slot="{ errors, field }"
    v-model="fieldValue"
    :name="'text-' + configurableField.name"
    :rules="{
      required:configurableField.isMandatory,
      max: maxInputLength,
      textConfigurableField: {
        maskPrompt: configurableField.maskPrompt,
        mustValidateMask: configurableField.mustValidateMask,
        validationMasks: configurableField.validationMasks
      }
    }"
    :validate-on-input="true"
    :validate-on-change="false"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div>
      <label
        :for="'text-' + configurableField.name"
        :class="{'required': configurableField.isMandatory}"
      >
        {{ configurableField.displayName }}
      </label>
      <div
        :class="defineClass(errors)"
      >
        <textarea
          v-if="configurableField.name === 'Summary'"
          :id="'text-' + configurableField.name"
          ref="input"
          v-bind="field"
          class="form-control"
          rows="3"
          :placeholder="getPlaceholder()"
          :value="fieldValue"
          :required="configurableField.isMandatory"
          :title="configurableField.maskPrompt"
          :disabled="disabled"
          :aria-invalid="errors.length > 0 && !showMultiplePlaceholder"
          :aria-describedby="'errorText_'+configurableField.name"
          @input="emitUpdatedEvent"
        />
        <input
          v-else
          :id="'text-' + configurableField.name"
          ref="input"
          v-bind="field"
          type="text"
          class="form-control text"
          :placeholder="getPlaceholder()"
          :value="fieldValue"
          :required="configurableField.isMandatory"
          :title="configurableField.maskPrompt"
          :disabled="disabled"
          :aria-invalid="errors.length > 0 && !showMultiplePlaceholder"
          :aria-describedby="'errorText_'+configurableField.name"
          @input="emitUpdatedEvent"
        >
        <div class="help-block">
          <small :id="'errorText_'+configurableField.name">{{ showMultiplePlaceholder ? '' : errors[0] }}</small>
        </div>
        <div
          v-if="shouldNotValidate && !disabled"
          class="help-block"
        >
          {{ getWarningMessage(errors) }}
        </div>
      </div>
    </div>
  </Field>
</template>