<template>
  <div class="form-group">
    <label
      for="addressSearchTerm"
      class="control-label"
    >{{ label }}</label>

    <div class="input-group">
      <!-- The enterKey: lookupAddress binding doesn't work when an item is selected from the list so remove for now -->
      <input
        id="addressSearchTerm"
        v-model="addressSearchTerm"
        type="search"
        autocomplete="off"
        class="form-control"
        :placeholder="$localize('AddressSearchTermPlaceholder')"
        @blur="hideDropdown"
      >

      <span class="input-group-btn">
        <button
          :disabled="!addressSearchTerm"
          class="btn btn-default"
          type="button"
          @click="search"
        >
          <span
            class="far fa-search"
            aria-hidden="true"
          />
          <span
            class="hidden-xs"
            aria-hidden="true"
          >{{ $localize('Search') }}</span>
          <span
            class="sr-only"
          >{{ $localize('Search') }}</span>
        </button>
      </span>
    </div>

    <div
      v-if="showAddressSearchResults"
      style="position: relative"
    >
      <ul
        v-if="!addressSearchResults.length && searching"
        class="dropdown-menu dropdown-menu-full-width"
      >
        <li class="disabled">
          <span>{{ $localize('AddressSearchInProgress') }}</span>
        </li>
      </ul>

      <ul
        v-if="!addressSearchResults.length && !searching"
        class="dropdown-menu dropdown-menu-full-width"
      >
        <li class="disabled">
          <span>{{ $localize('AddressSearchNoResults') }}</span>
        </li>
      </ul>

      <ul
        v-if="addressSearchResults.length"
        class="dropdown-menu dropdown-menu-full-width"
      >
        <li
          v-for="(address, index) in addressSearchResults"
          :key="index"
        >
          <a
            href="#"
            @click.prevent="setLocation(address)"
          >{{ address.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import logger from '@/Utils/logger';
import addressLookupRepository from '@/Repositories/addressLookupRepository';
import MapCoordinate from '@/VueComponents/SharedComponents/Map/Models/mapCoordinate';

defineProps<{
  label: string
}>();

const addressSearchTerm = ref('');
const showAddressSearchResults = ref(false);
const addressSearchResults = ref([]);
const searching = ref(false);

const emit = defineEmits(['change']);

const coordinate = defineModel<MapCoordinate>();

function search() {
  if (!addressSearchTerm.value.length) {
    return;
  }

  logger.debug('looking up address for \'%s\'', addressSearchTerm.value);

  addressSearchResults.value = [];
  searching.value = true;
  showAddressSearchResults.value = true;

  addressLookupRepository.lookupAddress(addressSearchTerm.value)
      .then(function (results) {
        addressSearchResults.value = results;

        searching.value = false;

        logger.debug('received %d results', results.length);
      })
      .catch(function (jqXhr) {

        showAddressSearchResults.value = false;

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileRetrievingAddressLookup', null, jqXhr);
        }
      });
}

function setLocation(address) {
  logger.debug('setting location to %f, %f', address.coordinate.latitude, address.coordinate.longitude);

  coordinate.value = { latitude: address.coordinate.latitude, longitude: address.coordinate.longitude };

  showAddressSearchResults.value = false;
  emit('change', { latitude: address.coordinate.latitude, longitude: address.coordinate.longitude });
}

function hideDropdown() {
  showAddressSearchResults.value = false;
  addressSearchResults.value = [];
}
</script>
